import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import rocketmanTransport from "../../services/RocketmanTransport";
import storage from "../../services/Storage/Storage";
import translate from "../../services/Translation/lang";
import AutoCashout from "../BetComponent/AutoCashout";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import TableInfoSvg from "../svg/table-info-svg";
import advancedAutoPlayStateHandler from "../../services/AdvancedAutoPlay/AdvancedAutoPlayStateHandler";
import { NumericFormat } from "react-number-format";

const MIN_CASHOUT_VAL = 1.1;

export default function AdvancedAutoPlay({
  show,
  setShowAdvancedAutoPlay,
  decrease,
  increase,
  onValueChange,
  isAllowed,
  onValueBlur,
  buttonParams,
  buttonIndex,
  handleCancelBet,
  setAutoCashValue,
  checkMinAutoCashout,
  handleChangeAutoBet,
  placeBet,
  button0Ballance,
  setDisabledCashout,
  currRound
}) {
  const [infinity, setInfinity] = useState("number");
  const [onWin, setOnWin] = useState(true);
  const [onLoss, setOnLoss] = useState(true);
  const [onWinValue, setOnWinValue] = useState(0.0);
  const [onLossValue, setOnLossValue] = useState(0.0);
  const [totalBetsValue, setTotalBetsValue] = useState("");
  const [stopOnProfitValue, setStopOnProfitValue] = useState(0.0);
  const [stopOnLossValue, setStopOnLossValue] = useState(0.0);
  const [profitValue, setProfitValue] = useState(0);
  const [lossValue, setLossValue] = useState(0);
  const [localError, setLocalError] = useState("");
  const [forceRerender, setForceRerender] = useState(false);
  const [roundEnded, setRoundEnded] = useState(false);
  const userSettingsLoaded = useRef(false);

  const totalBetsChange = useCallback((val) => {
    const max = rocketmanTransport.maxAutoPlayGames;
    if (Number(max) === 0) {
      let re = /^\d+(\.\d{0})?$/;
      setInfinity("image")
      

      if (!val) {
        setInfinity("image");
        setTotalBetsValue("");
      } else {
        if (Number(val) === 0) {
          setInfinity("image");
          setTotalBetsValue(val);
        } else {
          setInfinity("");
          
          let re = /^\d+(\.\d{0})?$/;
    
          if (re.test(val)) {
            //if (Number(val) > max) val = max;
            setTotalBetsValue(val);
          }
        }
      } 

    } else {
      if (!val) {
        setInfinity("number");
        setTotalBetsValue("");
      } else {
        if (Number(val) === 0) {
          setInfinity("number");
          setTotalBetsValue(val);
        } else {
          setInfinity("");
          
          let re = /^\d+(\.\d{0})?$/;
    
          if (re.test(val)) {
            if (Number(val) > max) val = max;
            setTotalBetsValue(val);
          }
        }
      }  
    }
  },[]);

  window.setByButtonAdvancedAutoPlay = (val, index) => {
    if (index == buttonIndex) {
      rocketmanTransport.setAdvancedAutoPlay(buttonIndex,false,buttonParams.val)
    }
  };
  
  const initialBalance = useRef(advancedAutoPlayStateHandler.advancedAutoPlayBalanceHandler.initialBalance);
  const balanceDiff = useRef(advancedAutoPlayStateHandler.advancedAutoPlayBalanceHandler.balanceDiff);
  const roundsPlayed = useRef(advancedAutoPlayStateHandler.advancedAutoPlayRoundsHandler.roundsPlayed);

  window.setInitialBalance = (buttonIndx, val) => {
    if (buttonIndex == buttonIndx) {
      initialBalance.current[buttonIndx] = val;
    }
  }

  window.setAdvancedAutoPlayBalance = (buttonIndx, val) => {
    if (buttonIndex == buttonIndx) {
      balanceDiff.current[buttonIndx] = val;
    }
  }

  window.setAdvancedAutoPlayRoundsPlayed = (buttonIndx, val) => {
    if (buttonIndex == buttonIndx) {
      roundsPlayed.current[buttonIndx] = val;
    }
  }

  window.setRoundEnded = setRoundEnded;

  const onWinChange = useCallback((val) => {
    const max = 1000000;
    let re;
    if (rocketmanTransport.noOfDecimals == 4) {
      re = /^\d+(\.\d{0,4})?$/;
    } else {
      re = /^\d+(\.\d{0,2})?$/;
    }

    if (re.test(val)) {
      if (Number(val) > max) val = max;
      setOnWinValue(val);
    }

    if (!val) {
      setOnWin(true);
      setOnWinValue(val);
    } else {
      if (Number(val) > 0) {
        setOnWin(false);
      } else {
        setOnWin(true);
      }
    }
  },[rocketmanTransport.noOfDecimals]);

  const hadnleStopOnProfitValue = useCallback((val) => {
    if (!val) {
      setStopOnProfitValue(val);
    } else {
      const max = 1000000;
      let re;
      if (rocketmanTransport.noOfDecimals == 4) {
        re = /^\d+(\.\d{0,4})?$/;
      } else {
        re = /^\d+(\.\d{0,2})?$/;
      }

      if (re.test(val)) {
        if (Number(val) > max) val = max;
        setStopOnProfitValue(val);
      }
    }
  },[rocketmanTransport.noOfDecimals])

  const hadnleStopOnLossValue = useCallback((val) => {
    if (!val) {
      setStopOnLossValue(val);
    } else {
      const max = 1000000;
      let re;
      if (rocketmanTransport.noOfDecimals == 4) {
        re = /^\d+(\.\d{0,4})?$/;
      } else {
        re = /^\d+(\.\d{0,2})?$/;
      }

      if (re.test(val)) {
        if (Number(val) > max) val = max;
        setStopOnLossValue(val);
      }
    }
  },[rocketmanTransport.noOfDecimals])

  const handleOnFocus = (e) => e.target.select();

  const handleOnWinChange = useCallback((e) => {
    if (e === true) setOnWinValue(0.0);
    setOnWin(!onWin);
  },[onWin])

  const onLossChange = useCallback((val) => {
    const max = 1000000;
    let re;
    if (rocketmanTransport.noOfDecimals == 4) {
      re = /^\d+(\.\d{0,4})?$/;
    } else {
      re = /^\d+(\.\d{0,2})?$/;
    }

    if (re.test(val)) {
      if (Number(val) > max) val = max;
      setOnLossValue(val);
    }

    if (!val) {
      setOnLoss(true);
      setOnLossValue(val);
    } else {
      setOnLoss(false);
    }
  },[rocketmanTransport.noOfDecimals]);

  const handleOnLossChange = useCallback((e) => {
    if (e === true) setOnLossValue(0.0);
    setOnLoss(!onLoss);
  },[onLoss]);

  const autoCashoutRefAdvanced = useRef();

  const handleAutoPlayStartStop = useCallback(() => {
    if (
      !rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
      buttonParams.gameState !== "initialState"
    ) {
      rocketmanTransport.localErrors(
        translate('please_wait_current_bets')
      );
      setLocalError(translate('please_wait_current_bets'));
      return;
    }

    if (Number(buttonParams.autoCashout) < MIN_CASHOUT_VAL) {
      rocketmanTransport.localErrors(
        `${translate('min_autocashout_value_must_be')} ${MIN_CASHOUT_VAL}`
      );
      setLocalError(`${translate('min_autocashout_value_must_be')} ${MIN_CASHOUT_VAL}`);
      return;
    }

    if (Number(buttonParams.val) < Number(rocketmanTransport.minBet)) {
      rocketmanTransport.localErrors(
        `${translate('min_bet_value_must_be')} ${rocketmanTransport.minBet}`
      );
      setLocalError(`${translate('min_bet_value_must_be')} ${rocketmanTransport.minBet}`);
      return;
    }

    if (
      !rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
      !rocketmanTransport.noCurrentBetsForButton(buttonIndex)
    ) {
      rocketmanTransport.localErrors(
        translate('please_wait_current_bets')
      );
      setLocalError(translate('please_wait_current_bets'));
      return;
    }

    if (
      !rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
      rocketmanTransport.noCurrentBetsForButton(buttonIndex)
    ) {
      if (rocketmanTransport.balance >= buttonParams.val) {
        buttonParams.autoBet = true;
        rocketmanTransport.setAdvancedAutoPlay(buttonIndex,true, buttonParams.val)
        placeBet(true);
        setShowAdvancedAutoPlay();
      } else {
        window.setShowNoFunds(true);
      }
    } else {
      if (buttonParams.gameState === "betActive") {
        handleCancelBet(buttonParams.ticketId, buttonIndex);
        handleChangeAutoBet(null, false)
      }
      if (buttonParams.gameState === "inProgress") {
        handleChangeAutoBet(null, true);
      }
      rocketmanTransport.setAdvancedAutoPlay(buttonIndex,false, buttonParams.val);
      buttonParams.autoBet = false;
      //setShowAdvancedAutoPlay();
    }
  },[rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress, buttonIndex,
      buttonParams.gameState,buttonParams.autoCashout,buttonParams.autoBet, buttonParams.val]);

  useEffect(() => {
    rocketmanTransport.advancedAutoPlayTotalBetsValue[buttonIndex].val =
      totalBetsValue;
  }, [totalBetsValue]);

  useEffect(() => {
    rocketmanTransport.advancedAutoPlayStopOnProfitValue[buttonIndex].val =
      stopOnProfitValue;
  }, [stopOnProfitValue]);

  useEffect(() => {
    rocketmanTransport.advancedAutoPlayStopOnLossValue[buttonIndex].val =
      stopOnLossValue;
  }, [stopOnLossValue]);

  useEffect(() => {
    rocketmanTransport.advancedAutoPlayOnLossPercent[buttonIndex].percent =
      onLossValue;
  }, [onLossValue]);

  useEffect(() => {
    rocketmanTransport.advancedAutoPlayOnWinPercent[buttonIndex].percent =
      onWinValue;
  }, [onWinValue]);

  useEffect(() => {
    rocketmanTransport.advancedRoundsPlayed[buttonIndex].played = roundsPlayed.current[buttonIndex];
  }, [roundsPlayed.current[buttonIndex]]);

  useEffect(() => {
    rocketmanTransport.setAdvancedBalanceDiff(buttonIndex, balanceDiff.current[buttonIndex])
  }, [balanceDiff.current[buttonIndex]]);

  useEffect(() => {
    // STOP ON TOTAL BETS
    if (
      rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
        totalBetsValue &&
          Number(totalBetsValue) > 0
    ) {
      if (roundsPlayed.current[buttonIndex] >= totalBetsValue) {
          handleChangeAutoBet(null, false);
          //handleCancelBet(buttonParams.ticketId, buttonIndex)
      }
      if (roundsPlayed.current[buttonIndex] >= totalBetsValue && !buttonParams.roundId) {
        rocketmanTransport.setAdvancedAutoPlay(buttonIndex,false,buttonParams.val);
      }
    } else {
      if (rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress && roundsPlayed.current[buttonIndex] >= rocketmanTransport.maxAutoPlayGames) {
        handleChangeAutoBet(null, false);
      }
      if (roundsPlayed.current[buttonIndex] >= rocketmanTransport.maxAutoPlayGames && !buttonParams.roundId) {
        rocketmanTransport.setAdvancedAutoPlay(buttonIndex,false,buttonParams.val);
      }
    }
  }, [buttonParams.roundId, buttonParams.autoBet, roundsPlayed.current[buttonIndex],totalBetsValue]);

  useEffect(() => {
    // SET PROFIT STOP
    if (
      rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
      Number(stopOnProfitValue) > 0 &&
      !Number(profitValue) > 0
    ) {
      rocketmanTransport.advancedAutoPlayProfitValue[buttonIndex].val = Number(button0Ballance) + Number(stopOnProfitValue);
      setProfitValue(Number(button0Ballance) + Number(stopOnProfitValue));
    }

    // SET LOSS STOP
    if (
      rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
      Number(stopOnLossValue) > 0 &&
      !Number(lossValue) > 0
    ) {
      rocketmanTransport.advancedAutoPlayLossValue[buttonIndex].val = Number((Number(button0Ballance) - Number(stopOnLossValue)).toFixed(rocketmanTransport.noOfDecimals));
      setLossValue(Number(button0Ballance) - Number(stopOnLossValue));
    }

    if (localError) setLocalError("");
  }, [rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress]);

  useEffect(() => {
    if (!rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress) {
      rocketmanTransport.advancedAutoPlayLossValue[buttonIndex].val = 0;
      setLossValue(0);
      rocketmanTransport.advancedAutoPlayProfitValue[buttonIndex].val = 0;
      setProfitValue(0);
    }
  }, [rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress]);

  useEffect(() => {
    if (localError) setLocalError("");
    if (!userSettingsLoaded.current && show) {
      userSettingsLoaded.current = true;
      // get user saved recently played values from storage
      const userSavedAdvPlaySettings = storage.get(`sosageBanana${[buttonIndex]}`);
      if (userSavedAdvPlaySettings) {
        try {
          const parsedSettings = JSON.parse(userSavedAdvPlaySettings);
          if (Object.keys(parsedSettings).length == 7) {
            const amnt = parsedSettings?.betAmount;
            const autCsht = parsedSettings?.autoCashout;
            const ttlBets = parsedSettings?.totalBets;
            const onWinVl = parsedSettings?.onWin;
            const onLossVl = parsedSettings?.onLoss;
            const stpProfit = parsedSettings?.stopOnProfit;
            const stpLoss = parsedSettings?.stopOnLoss;
            const event = {
              target: {
                value: rocketmanTransport.defBet.toFixed(
                  rocketmanTransport.noOfDecimals
                ),
              },
            };
            event.target.value = amnt;
            setAutoCashValue(autCsht);
            setTotalBetsValue(ttlBets);
            setOnWinValue(onWinVl);
            setOnLossValue(onLossVl);
            setStopOnProfitValue(stpProfit);
            setStopOnLossValue(stpLoss);
            setForceRerender(!forceRerender);
            onValueChange({ floatValue: amnt });
            // isAllowed(event);

            // SET INFINITY ON SHOW
            if (Number(rocketmanTransport.maxAutoPlayGames) === 0) {
              if (ttlBets == "") {
                setInfinity("image")
              } else if (ttlBets == 0) {
                setInfinity("image");
              } else {
                setInfinity("")
              }
            } else {
              if (ttlBets == "") {
                setInfinity("number");
              } else if (ttlBets == 0) {
                setInfinity("number");
              } else {
                setInfinity("");
              }
            }
          }
        } catch (error) {
          console.error("There was an error parsing saved advanced autoplay settings")
        }
      }
    }
  }, [show]);

  useEffect(() => {    
    setRoundEnded(rocketmanTransport.data.end);
  }, []);

  return (
    <Modal
      className="pop-up astronaut-popup vip-popup advanced-autoplay-popup"
      show={show}
      onHide={() => setShowAdvancedAutoPlay(false)}
      animation={false}
      backdropClassName="over-popup-backdrop"
      enforceFocus={false}
    >{useMemo(() => 
        (<Modal.Header>
        <h1>{translate("advanced_autoplay")} {buttonIndex + 1}</h1>
        </Modal.Header>),
      [buttonIndex, translate("advanced_autoplay")])}
      {/* <div className="aap-loader"></div> */}
      <Modal.Body>
        <div className="astronaut-pop-up-body">
          {/* BET AMOUNT */}
          {useMemo(() => (
            <div className="aap-input-holder">
              <div className={"aap-label " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('bet_amount')}</div>
              <div className={"play-now-number-input "}>
                <button
                  onClick={() => decrease()}
                  className="play-now-number-input-decrese"
                  disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                >
                  <div className="minus"></div>
                </button>
                {/* <input
                  onChange={onChange}
                  onBlur={onValueBlur}
                  value={buttonParams.val}
                  disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress || rocketmanTransport.fixedBet.length > 0}
                /> */}
                <NumericFormat disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress || rocketmanTransport.fixedBet.length > 0}
                  valueIsNumericString={true}
                  value={buttonParams.val}
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  decimalScale={rocketmanTransport.amountFormat === "###,###" ? 0 : (rocketmanTransport.noOfDecimals || 2)}
                  onValueChange={onValueChange}
                  isAllowed={isAllowed}
                  // fixedDecimalScale={rocketmanTransport.noOfDecimals > 0 ? true : false}
            />
                <button
                  disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                  onClick={() => increase()}
                  className="play-now-number-input-increse"
                >
                  <div className="plus"></div>
                </button>
              </div>
            </div>
          ),[buttonIndex,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress,buttonParams.val])}
          <div className="aap-double-input-holder">
            {/* AUTOCASHOUT */}
            {useMemo(() => (
              <div className="aap-input-holder">
                <AutoCashout
                  advancedAutoPlayShow={true}
                  advancedAutoPlay={
                    rocketmanTransport.advancedAutoplayInProgress[buttonIndex]
                      .inProgress
                  }
                  cashoutText={translate("set_auto_cashout")}
                  gameState={buttonParams.gameState}
                  setAutoCashValue={setAutoCashValue}
                  autoBet={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                  checkMinAutoCashout={checkMinAutoCashout}
                  autoCashValue={buttonParams.autoCashout}
                  currentBet={buttonParams.val}
                  buttonIndex={buttonIndex}
                  ticketId={buttonParams.ticketId}
                  innerRef={autoCashoutRefAdvanced}
                  ticketType={buttonParams.ticketType} 
                  minCashoutMultiplier={buttonParams.minCashoutMultiplier}
                  setDisabledCashout={setDisabledCashout}
                />
              </div>
            ),[buttonIndex,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress,buttonParams.gameState,buttonParams.ticketType,
                buttonParams.minCashoutMultiplier, buttonParams.autoCashout,buttonParams.val,buttonParams.ticketId,autoCashoutRefAdvanced])}
            {/* TOTAL BETS */}
            {useMemo(() => (
              <div className="aap-input-holder">
                <div className={"aap-label " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('total_bets')}</div>
                <div className="aap-input-icon-right">
                  <input
                    type="text"
                    onChange={(e) => totalBetsChange(e.target.value)}
                    value={totalBetsValue}
                    className="aap-input"
                    onFocus={(e) => handleOnFocus(e)}
                    disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                  />
                  {infinity === "number" ? (
                    <span
                      className="aap-input-icon position-right infinite"
                    >{rocketmanTransport.maxAutoPlayGames}
                    </span>
                  ) : infinity === "image" ? <img
                      className="aap-input-icon position-right"
                      src="/svg/infinity.svg"
                      alt="crown"
                      /> : null}
                </div>
              </div>
            ),[totalBetsValue,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress,buttonIndex,infinity, translate('total_bets'), rocketmanTransport.r7css])}
          </div>
          {/* ON WIN */}
          {useMemo(() => (
            <div className="aap-input-holder">
              <div className={"aap-label " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('on_win')}</div>
              <div className="aap-input-icon-left with-switch">
                <input
                  onChange={(e) => onWinChange(e.target.value)}
                  type="text"
                  onFocus={(e) => handleOnFocus(e)}
                  value={onWinValue}
                  className="aap-input"
                  disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                />
                <img
                  className="aap-input-icon position-left"
                  src="/svg/percentage.svg"
                  alt="percentage"
                />
                <div className="aap-switch">
                  <Switch
                    onChange={(e) => handleOnWinChange(e)}
                    checked={!Number(onWinValue)}
                    disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                    className={!Number(onWinValue) ? "move-left react-switch" : "react-switch"}
                    onColor="#202558"
                    offColor="#202558"
                    borderRadius={26}
                    width={140}
                    height={30}
                    handleDiameter={28}
                    activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
                    uncheckedIcon={
                      <div className={"switcher-on-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('reset')}</div>
                    }
                    checkedIcon={
                      <div className={"switcher-off-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('increase')}</div>
                    }
                    uncheckedHandleIcon={
                      <div className={"switcher-off " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('increase')}</div>
                    }
                    checkedHandleIcon={<div className={"switcher-on " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('reset')}</div>}
                  />
                </div>
              </div>
            </div>
          ),[onWinValue,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress, buttonIndex,onWin, translate('on_win'), translate('reset'), translate('increase'), rocketmanTransport.r7css ])}
          {/* ON LOSS */}
          {useMemo(() => (
            <div className="aap-input-holder">
              <div className={"aap-label " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('on_loss')}</div>
              <div className="aap-input-icon-left with-switch">
                <input
                  type="text"
                  value={onLossValue}
                  onChange={(e) => onLossChange(e.target.value)}
                  onFocus={(e) => handleOnFocus(e)}
                  className="aap-input"
                  disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                />
                <img
                  className="aap-input-icon position-left"
                  src="/svg/percentage.svg"
                  alt="percentage"
                />
                <div className="aap-switch">
                  <Switch
                    onChange={(e) => handleOnLossChange(e)}
                    checked={!Number(onLossValue)}
                    className={!Number(onLossValue) ? "move-left react-switch" : "react-switch"}
                    onColor="#202558"
                    offColor="#202558"
                    borderRadius={26}
                    width={140}
                    height={30}
                    disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                    handleDiameter={28}
                    activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
                    uncheckedIcon={
                      <div className={"switcher-on-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('reset')}</div>
                    }
                    checkedIcon={
                      <div className={"switcher-off-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('increase')}</div>
                    }
                    uncheckedHandleIcon={
                      <div className={"switcher-off " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('increase')}</div>
                    }
                    checkedHandleIcon={<div className={"switcher-on " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('reset')}</div>}
                  />
                </div>
              </div>
            </div>
          ),[onLossValue,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress, buttonIndex,onLoss, translate('on_loss'), translate('reset'), translate('increase'), rocketmanTransport.r7css])}
          <div className="aap-double-input-holder mb-4">
            {useMemo(() => (
             <div className="aap-input-holder">
              <div className={"aap-label " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('stop_on_profit')} 
              <TableInfoSvg
                  data-tip={translate('stop_on_profit_tooltip')}
                  data-for="stopOnProfit"
                  data-multiline="true"
                  className="aap-input-info"
                />
              </div>
              <div className="aap-input-icon-right">
                <input
                  type="text"
                  onChange={(e) => hadnleStopOnProfitValue(e.target.value)}
                  value={stopOnProfitValue}
                  onFocus={(e) => handleOnFocus(e)}
                  className="aap-input"
                  disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                />
                <ReactTooltip
                  className={"advanced-autoplay-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                  place="bottom"
                  effect="solid"
                  backgroundColor="#ffbf19"
                  id="stopOnProfit"
                />
              </div>
            </div> 
            ),[stopOnProfitValue,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress, buttonIndex, translate('stop_on_profit'), translate('stop_on_profit_tooltip'), rocketmanTransport.r7css])}
            {/* STOP ON LOSS */}
            {useMemo(() => (
              <div className="aap-input-holder">
                <div className={"aap-label " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('stop_on_loss')}
                <TableInfoSvg
                    data-tip={translate('stop_on_loss_tooltip')}
                    data-for="stopOnLoss"
                    data-multiline="true"
                    className="aap-input-info"
                  />
                </div>
                <div className="aap-input-icon-right">
                  <input
                    type="text"
                    onChange={(e) => hadnleStopOnLossValue(e.target.value)}
                    value={stopOnLossValue}
                    onFocus={(e) => handleOnFocus(e)}
                    className="aap-input"
                    disabled={rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress}
                  />
                  <ReactTooltip
                    className={"advanced-autoplay-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                    place="bottom"
                    effect="solid"
                    backgroundColor="#ffbf19"
                    id="stopOnLoss"
                  />
                </div>
              </div>
            ),[stopOnLossValue,rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress,buttonIndex, translate('stop_on_loss'), translate('stop_on_loss_tooltip'), rocketmanTransport.r7css])}
          </div>
          {useMemo(() => (
             <div className="aap-label warning">{localError}</div>
          ),[localError])}
          <div className="mb-3">
            {useMemo(() => (
              <PrimaryButton
                // title={translate("ok_close")}
                disabled={
                  !rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress &&
                  buttonParams.gameState !== "initialState"
                    ? true
                    : false
                }
                title={
                  !rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress
                    ? translate("start_with_autoplay")
                    : translate("stop_autoplay")
                }
                onClick={() => handleAutoPlayStartStop()}
              />
            ),[rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress,buttonIndex,buttonParams.gameState,handleAutoPlayStartStop])}
          </div>
          {useMemo(() => ( 
            <SecondaryButton
              title={translate("cancel")}
              onClick={() => setShowAdvancedAutoPlay(false)}
            />),[translate("cancel")])}
        </div>
      </Modal.Body>
    </Modal>
  );
}
